.App {
  text-align: center;
}

body {
  margin: 0 !important;
  font-family: 'Nunito Sans', sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border-radius: initial !important;
  border-top-left-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
  box-shadow: 0 0 0px 100px #fff inset !important;
  -webkit-box-shadow: 0 0 0px 100px #fff inset !important;
  -webkit-text-fill-color: #2D3748 !important;
}

.MuiPickersPopper-paper {
  background-color: #0B3835 !important;
  color: white !important;
}
.MuiIconButton-root {
  color: white !important;
}

.MuiPickersPopper-paper .MuiTypography-root {
  color: white !important;

}
.MuiPickersDay-root.Mui-disabled {
  color: #848484 !important;
}

.MuiPickersYear-root.Mui-disabled {
  color: #848484 !important;
}

.MuiPickersDay-root {
  color: white !important;
}

.MuiPickersYear-yearButton.Mui-selected {
  background-color: #07BEB6 !important;
}
.MuiPickersMonth-monthButton.Mui-disabled {
  color: #848484 !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #07BEB6 !important;
}

.MuiPickersMonth-monthButton.Mui-selected {
  background-color: #07BEB6 !important;

}


